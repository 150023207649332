import { Articoli } from "@/models/entities/articoli.interface";
import { ApiService } from "../api.service";

const CONTROLLER = "Articoli";

class ArticoliService extends ApiService {
  readAll( Itype: string ) {
    const params = { params: {type: Itype} }; 
    return this.getAll<Articoli[]>(CONTROLLER, params );
  }
} 
export const articoliservice = new ArticoliService();

  